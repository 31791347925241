<template>
    <div>
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item >教研中心</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/teacherRecord' }">教师录单</el-breadcrumb-item>
                <el-breadcrumb-item>新增</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="detail-info">
            <el-form ref="form" :model="form" :rules="rules" label-width="100px" size="mini" >
				<el-form-item label="名称" label-position="left" class="input" prop="name">
				    <el-input 
						style="width:200px"  
						v-model="form.name" 
						maxlength="50"
						show-word-limit
						placeholder="请输入名称"
						>
					</el-input>
				</el-form-item>
				<el-form-item label="图片" label-position="left">
					<pasteImage @onSuccess="onSuccess" :uploadPath="uploadPath" @onRomove="onRemove"></pasteImage>
				</el-form-item>
                <el-form-item label="类型：" label-position="left" class="input" prop="type">
                    <el-select style="width: 200px" v-model="form.type" placeholder="请选择" size="small" @change="selectType">
                        <el-option
                            v-for="item in types"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
				<el-form-item label="上课类型：" label-position="left" class="input" prop="classType">
				    <el-select style="width: 200px" v-model="form.classType" placeholder="请选择" size="small" @change="selectClassType">
				        <el-option
				            v-for="item in classTypes"
				            :key="item.value"
				            :label="item.label"
				            :value="item.value">
				        </el-option>
				    </el-select>
				</el-form-item>
				<el-form-item label="数量/时长" label-position="left" class="input" prop="workload">
				    <el-input style="width: 200px" v-model="form.workload" placeholder="请输入数量/时长"></el-input>
				</el-form-item>
				<el-form-item label="备注" prop="desc">
				    <el-input 
						style="width: 40%;"
						type="textarea"
						:rows="10"
						maxlength="255"
						show-word-limit
						placeholder="请输入内容"
						v-model="form.desc">
					</el-input>
				</el-form-item>
                <el-form-item>
                    <el-button size="small" type="primary" :loading="loading" @click="onSubmit">保存</el-button>
                    <router-link to="/teacherRecord" class="router-link" ><el-button style="margin-left: 10px" size="small">取消</el-button></router-link>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
    import {mapActions} from "vuex"
	import pasteImage from '../../../components/pasteImage/index'
    export default {
        name: "add",
		components: {pasteImage},
        data() {
            return {
				uploadPath: 'teacherRecord',
                loading:false,
                form: {
                    type: '',
					classType: '',
					name: '',
                    workload: '',
					desc: '',
					pics: [],
                },
                types: [
                    {
                        value: 1,
                        label: '课件'
                    },
                    {
                        value: 2,
                        label: '课时'
                    }
                ],
				classTypes: [
					{
					    value: 1,
					    label: '付费直播'
					},
					{
					    value: 2,
					    label: '免费直播'
					},
					{
					    value: 3,
					    label: '体验直播'
					},
					{
					    value: 4,
					    label: '录播课'
					}
				],
				rules: {
					name: [
						{ required: true, message: '请输入名称', trigger: 'blur' },
						{ max: 50, message: '长度最大50个字符', trigger: 'blur' }
					],
					type: [
						{ required: true, message: '类型必选', trigger: 'blur' }
					],
					classType: [
						{ required: true, message: '上课类型必选', trigger: 'blur' }
					],
					workload: [
						{ required: true, message: '数量/时长必须', trigger: 'blur' }
					]
				}
            }
        },
        methods: {
            ...mapActions('teacherRecord',['addTeacherRecord']),
			onSubmit(){
				this.$refs['form'].validate((valid) => {
					  if (valid) {
						  this.saveTeacherRecord()
					  } else {
						return false
					  }
				});
			},
            async saveTeacherRecord() {
                let _this = this
                this.loading = true
                await this.addTeacherRecord(this.form).then( res => {
                    if(res.ret == 0){
                        _this.$message.success('新增成功！')
                        _this.$router.push('/teacherRecord')
                    }
                })
                this.loading = false
            },
            selectType(val){
                this.form.type = val
            },
			selectClassType(val){
				this.form.classType = val
			},
			onSuccess(path) {
				var pics = path + ''
				var that = this
				var pathList = pics.split(',')
				var relPath = pathList[pathList.length - 1]
				that.form.pics.push(relPath)
			},
			// 图片移除
			onRemove(index,file){
				this.form.pics.splice(index,1)
			}
        }
    }
</script>

<style>
	.el-form-item__label {
        text-align: justify;
    }
</style>
